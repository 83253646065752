import { useAuthApi } from "~/api/useAuthApi";

export function useAuth() {
  const { login: loginRequest, logout: logoutRequest } = useAuthApi();
  const login = async (credentials: any) => {
    try {
      await loginRequest(credentials);
      window.location.replace("/account/dashboard");
    } catch (error: any) {
      throw error.data;
    }
  };

  const logout = async () => {
    try {
      await logoutRequest();
      window.location.replace("/");
    } catch (error: any) {
      throw error.data;
    }
  };

  return {
    login,
    logout,
  };
}
